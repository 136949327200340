import React from "react";
import "../App.css";
import "./Whatsapp.css";

function Whatsapp() {
  return (
    <div>
      <div className="whatsapp-container">
        <a
          className="whats-app"
          href="https://api.whatsapp.com/send/?phone=60123779288"
        >
          <i className="fa fa-whatsapp my-float"></i>
          <span className="whatsapp-info">60123779288</span>
        </a>
      </div>
    </div>
  );
}

export default Whatsapp;
