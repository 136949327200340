import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./components/pages/Home";
import {
  BrowserRouter as Router,
  HashRouter as HashRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Services from "./components/pages/Services";
import Products from "./components/pages/Products";
import SignUp from "./components/pages/SignUp";

import Product_A from "./components/pages/Product_A";

import AboutUs from "./components/pages/AboutUs";

function App() {
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/services" component={Services} />
          <Route path="/products" component={Products} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/product-a" component={Product_A} />
          <Route path="/product/:id" component={Product_A} />

          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          <Route path="*" component={Home} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
