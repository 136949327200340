import React from "react";
import "../../App.css";
import ProductCards from "../ProductCards";
import Footer from "../Footer";
import Whatsapp from "../Whatsapp";

export default function Products() {
  return (
    <>
      {/* <h1 className="products">PRODUCTS</h1> */}
      <ProductCards />
      <Footer />
      <Whatsapp />
    </>
  );
}
