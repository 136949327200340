import React from "react";
import { Redirect } from "react-router";
import "../Product/Product.css";

function Product(props) {
  var imgContainer = "";
  var validPage = false;
  var title = "";

  // env
  var folderName = "";

  switch (props.product_name) {
    case "belt-conveyor":
      title = "Belt Conveyor";
      imgContainer = (
        <div>
          <h1 className="prod-title">Belt Conveyor</h1>
          <div>
            <p className="prod-desc">
              Several belt type for selection according to the application such
              PVC, PU, Silicone, Fabric belt. Our available belt conveyor type
              is MINICON, SUPERMINI &amp; MEDIUM DUTY. Each type with the
              different structure &amp; load capacity to meet the requirement of
              the production.
            </p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName + "/images/belt/belt conveyor - Supermini.jpg"
                  }
                  alt="Supermini"
                  label="Supermini"
                ></img>
              </div>
              <div class="desc">Belt Conveyor - Supermini</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/belt/belt conveyor food grade.jpg"}
                  alt="Food Grade"
                  label="Food Grade"
                ></img>
              </div>
              <div class="desc">Belt Conveyor Food Grade</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/Belt conveyor for metal detector.jpg"
                  }
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Belt Conveyor for Metal Detector</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/belt conveyor inclined (rough top belt).jpg"
                  }
                  alt="Inclined"
                  label="Inclined"
                ></img>
              </div>
              <div class="desc">Belt Conveyor Inclined (rough top belt)</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/Belt conveyor inclined with PVC divider .jpg"
                  }
                  alt="Inclined with PVC divider"
                  label="Inclined with PVC divider"
                ></img>
              </div>
              <div class="desc">Belt Conveyor Inclined with PVC divider</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/Belt conveyor inclined with T-cleat.jpg"
                  }
                  alt="Inclined with T-cleat"
                  label="Inclined with T-cleat"
                ></img>
              </div>
              <div class="desc">Belt conveyor inclined with T-cleat</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/belt conveyor metal structure.jpg"
                  }
                  alt="Metal Structure"
                  label="Metal Structure"
                ></img>
              </div>
              <div class="desc">Belt Conveyor metal structure</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName + "/images/belt/belt conveyor width 1000mm.jpg"
                  }
                  alt="Width 1000mm"
                  label="Width 1000mm"
                ></img>
              </div>
              <div class="desc">Belt Conveyor Width 1000mm</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/belt conveyor with signboard + buzzer.jpg"
                  }
                  alt="Signboard + Buzzer"
                  label="Signboard + Buzzer"
                ></img>
              </div>
              <div class="desc">Belt Conveyor with Signboard + Buzzer</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/Belt conveyor with SUS platform.jpg"
                  }
                  alt="SUS Platform"
                  label="SUS Platform"
                ></img>
              </div>
              <div class="desc">Belt Conveyor with SUS platform</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/Belt conveyor with SUS side table.jpg"
                  }
                  alt="SUS side table"
                  label="SUS side table"
                ></img>
              </div>
              <div class="desc">Belt Conveyor with SUS side table</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/belt conveyor with workstation+lamp.jpg"
                  }
                  alt="Workstation + Lamp"
                  label="Workstation + Lamp"
                ></img>
              </div>
              <div class="desc">Belt Conveyor with Workstation + Lamp</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/belt/belt conveyor with control panel + tower lamp.jpg"
                  }
                  alt="Control Panel + Tower Lamp"
                  label="Control Panel + Tower Lamp"
                ></img>
              </div>
              <div class="desc">
                Belt Conveyor with Control Panel + Tower Lamp
              </div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
    case "roller-conveyor":
      title = "Roller Conveyor";
      imgContainer = (
        <div>
          <h1 className="prod-title">Roller Conveyor</h1>
          <div>
            <p className="prod-desc">
              The rollers material can use for Mild Steel with
              galvanizing/Stainless Steel/PVC. The structure of conveyor is Mild
              Steel with epoxy painted or Stainless Steel. Roller conveyor is
              considering the load capacity , product size, motorized or manual,
              product characteristic.
            </p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/roller/roller conveyor with alum guide with PVC .jpg"
                  }
                  alt="Alum Guide with PVC"
                  label="Alum Guide with PVC"
                ></img>
              </div>
              <div class="desc">Alum Guide with PVC</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/roller/roller conveyor with low leg .jpg"
                  }
                  alt="Food Grade"
                  label="Food Grade"
                ></img>
              </div>
              <div class="desc">Low Leg</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/roller/taper roller conveyor .jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Tapper</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/roller/Roller Conveyor + Tank.jpg"}
                  alt="Roller Conveyor + Tank"
                  label="Roller Conveyor + Tank"
                ></img>
              </div>
              <div class="desc">Roller Conveyor + Tank</div>
            </div>
          </div>
        </div>
      );

      validPage = true;
      break;
    case "chain-conveyor":
      title = "Chain Conveyor";
      imgContainer = (
        <div>
          <h1 className="prod-title">Chain Conveyor</h1>
          <div>
            <p className="prod-desc">
              Various type of chain model with different material (POM,
              Stainless Steel, Steel) The selection of chain is depending on the
              product conditions, weight &amp; dimension. Many accessories can
              add on to enhance the functioning of the chain conveyor such side
              guide, wear strip, clamp &amp; etc.
            </p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/chain/Apron chain conveyor with guide.jpg"
                  }
                  alt="Apron Chain Conveyor"
                  label="Apron Chain Conveyor"
                ></img>
              </div>
              <div class="desc">Apron Chain Conveyor</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/chain/SUS table top chain conveyor .jpg"
                  }
                  alt="SUS Table Top with SUS Side Table"
                  label="SUS Table Top with SUS Side Table"
                ></img>
              </div>
              <div class="desc">SUS Table Top with SUS Side Table</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/chain/SUS table top chain conveyor + SUS side table .jpg"
                  }
                  alt="SUS Side Table"
                  label="SUS Side Table"
                ></img>
              </div>
              <div class="desc">SUS Side Table</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName + "/images/chain/table top chain conveyor .jpg"
                  }
                  alt="Plastic Table Top Chain"
                  label="Plastic Table Top Chain"
                ></img>
              </div>
              <div class="desc">Plastic Table Top Chain</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/chain/Curve Chain Conveyor.jpg"}
                  alt="Curve Chain Conveyor"
                  label="Curve Chain Conveyor"
                ></img>
              </div>
              <div class="desc">Curve Chain Conveyor</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/chain/Modular Chain Conveyor.jpg"}
                  alt="Modular Chain Conveyor"
                  label="Modular Chain Conveyor"
                ></img>
              </div>
              <div class="desc">Modular Chain Conveyor</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
    case "custom-made-conveyor":
      title = "Custom Made Conveyor";
      imgContainer = (
        <div>
          <h1 className="prod-title">Custom Made Conveyor</h1>
          <div>
            <p className="prod-desc"></p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/ball transfer table.jpg"}
                  alt="Alum Guide with PVC"
                  label="Alum Guide with PVC"
                ></img>
              </div>
              <div class="desc">Ball Transfer Table</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/oven conveyor .jpg"}
                  alt="Food Grade"
                  label="Food Grade"
                ></img>
              </div>
              <div class="desc">Oven Conveyor</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/oven conveyor 3.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Oven Conveyor</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/SUS tray system .jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">SUS tray system</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/turn table unit.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Turn Table Unit</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName +
                    "/images/custom/aluminium extrusion profile structure.jpg"
                  }
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Aluminium Extrusion Profile Structure</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/pcb-loader.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">PCB Loader Unloader Pusher</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/Belt CV with guide.jpg"}
                  alt="Belt CV with guide"
                  label="Belt CV with guide"
                ></img>
              </div>
              <div class="desc">Belt CV with guide</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/Check Weigher.jpg"}
                  alt="Check Weigher"
                  label="Check Weigher"
                ></img>
              </div>
              <div class="desc">Check Weigher</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/custom/Telescopic Conveyor.jpg"}
                  alt="Telescopic Conveyor"
                  label="Telescopic Conveyor"
                ></img>
              </div>
              <div class="desc">Telescopic Conveyor</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
    case "conveyor-components":
      title = "Conveyor Components";
      imgContainer = (
        <div>
          <h1 className="prod-title">Conveyor Components</h1>
          <div>
            <p className="prod-desc"></p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/belting.jpg"}
                  alt="Alum Guide with PVC"
                  label="Alum Guide with PVC"
                ></img>
              </div>
              <div class="desc">Belting</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={
                    folderName + "/images/components/conveyor_metal_parts.jpg"
                  }
                  alt="Food Grade"
                  label="Food Grade"
                ></img>
              </div>
              <div class="desc">Conveyor Metal Parts</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/gear_box.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">AC Motor/Gearhead/Speed Controller</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/IMG_0378.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Sprocket</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/M16 Knuckle feet.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">M16 Knuckle feet</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/modular.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Modular</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/plastic_chain.png"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Plastic Chain</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/Profiles.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Profiles</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/pulley set minicon.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Pulley Set Minicon</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/pulleys.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Pulleys</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/pvc_endcap.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">PVC Endcap</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/side_guide_holder.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Side Guide Holder</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/sprocket.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Sprocket</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery">
              <div>
                <img
                  src={folderName + "/images/components/suction_cup.jpg"}
                  alt="Metal Detector"
                  label="Metal Detector"
                ></img>
              </div>
              <div class="desc">Suction Cup</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;

    case "ac-motor-gearhead-speed-controller":
      title = "AC Motor/Gearhead/Speed Controller";
      imgContainer = (
        <div>
          <h1 className="prod-title">
            AC Motor/Gearhead/Speed <br></br>Controller
          </h1>
          <div>
            <p className="prod-desc"></p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/ac/yawa 40w + logo.jpg"}
                  alt="Yawa 40W"
                  label="Yawa 40W"
                ></img>
              </div>
              <div class="desc">Yawa 40W</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/ac/yawa 90w + logo.jpg"}
                  alt="Yawa 90W"
                  label="Yawa 90W"
                ></img>
              </div>
              <div class="desc">Yawa 90W</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/ac/yawa gearhead + logo.jpg"}
                  alt="Yawa Gearhead"
                  label="Yawa Gearhead"
                ></img>
              </div>
              <div class="desc">Yawa Gearhead</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/ac/yawa speed ctrl + logo.jpg"}
                  alt="Yawa Speed Ctrl"
                  label="Yawa Speed Ctrl"
                ></img>
              </div>
              <div class="desc">Yawa Speed Ctrl</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/ac/yawa speed ctrl + logo_1.jpg"}
                  alt="Yawa Speed Ctrl"
                  label="Yawa Speed Ctrl"
                ></img>
              </div>
              <div class="desc">Yawa Speed Ctrl</div>
            </div>
          </div>

          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/ac/yawa 90w motor + logo.jpg"}
                  alt="Yawa 90W Motor"
                  label="Yawa 90W Motor"
                ></img>
              </div>
              <div class="desc">Yawa 90W Motor</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
      // case "office-racking":
      // title = "Office Racking (5 Level & 7 Level)";
      // imgContainer = (
      //   <div>
      //     <h1 className="prod-title">
      //       Office Racking <br></br>(5 Level & 7 Level)
      //     </h1>
      //     <div>
      //       <p className="prod-desc"></p>
      //     </div>
      //     <br></br>
      //     <div class="responsive">
      //       <div className="gallery-portrait">
      //         <div>
      //           <img
      //             src={folderName + "/images/used/Office Racking (5 Level & 7 Level)/library book rack.jpg"}
      //             alt="Library book rack"
      //             label="Library book rack"
      //           ></img>
      //         </div>
      //         <div class="desc">Library book rack</div>
      //       </div>
      //     </div>
      //     <div class="responsive">
      //       <div className="gallery-portrait">
      //         <div>
      //           <img
      //             src={folderName + "/images/used/Office Racking (5 Level & 7 Level)/RACK DIMENSION.jpg"}
      //             alt="Rack Dimension"
      //             label="Rack Dimension"
      //           ></img>
      //         </div>
      //         <div class="desc">Rack Dimension</div>
      //       </div>
      //     </div>
      //   </div>
      // );
      // validPage = true;
      // break;
      case "used-band-sealing-machine":
      title = "Used Band Sealing Machine (Horizontal) Model FR900";
      imgContainer = (
        <div>
          <h1 className="prod-title">
            Used Band Sealing Machine <br></br>(Horizontal) Model FR900
          </h1>
          <div>
            <p className="prod-desc"></p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Band Sealing Machine (Horizontal) Model FR900/band sealer (front).jpg"}
                  alt="Band Sealer (Front)"
                  label="Band Sealer (Front)"
                ></img>
              </div>
              <div class="desc">Band Sealer (Front)</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Band Sealing Machine (Horizontal) Model FR900/band sealer (side).jpg"}
                  alt="Band Sealer (side)"
                  label="Band Sealer (side)"
                ></img>
              </div>
              <div class="desc">Band Sealer (side)</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Band Sealing Machine (Horizontal) Model FR900/band sealer (top panel).jpg"}
                  alt="Band Sealer (Top Panel)"
                  label="Band Sealer (Top Panel)"
                ></img>
              </div>
              <div class="desc">Band Sealer (Top Panel)</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
      case "used-belt-conveyor":
      title = "Used Belt Conveyor";
      imgContainer = (
        <div>
          <h1 className="prod-title">
            Used Belt Conveyor<br></br>
          </h1>
          <div>
            <p className="prod-desc"></p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Belt Conveyor/Used Belt Conveyor with SUS side table & Lighting Holder (size W500 x L8000mm x H760mm).jpg"}
                  alt="Used Belt Conveyor with SUS side table & Lighting Holder (size W500 x L8000mm x H760mm)"
                  label="Used Belt Conveyor with SUS side table & Lighting Holder (size W500 x L8000mm x H760mm)"
                ></img>
              </div>
              <div class="desc">Used Belt Conveyor with SUS side table & Lighting Holder (size W500 x L8000mm x H760mm)</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Belt Conveyor/Used Belt Conveyor with SUS side table (2) Size W500 x L10000 x H760mm.jpg"}
                  alt="Used Belt Conveyor with SUS side table (2) Size W500 x L10000 x H760mm"
                  label="Used Belt Conveyor with SUS side table (2) Size W500 x L10000 x H760mm"
                ></img>
              </div>
              <div class="desc">Used Belt Conveyor with SUS side table (2) Size W500 x L10000 x H760mm</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Belt Conveyor/Used Belt Conveyor witth SUS Side Table Size W500 x L8000 x H760mm.jpg"}
                  alt="Used Belt Conveyor witth SUS Side Table Size W500 x L8000 x H760mm"
                  label="Used Belt Conveyor witth SUS Side Table Size W500 x L8000 x H760mm"
                ></img>
              </div>
              <div class="desc">Used Belt Conveyor witth SUS Side Table Size W500 x L8000 x H760mm</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
      case "used-dehumidifier-240l":
      title = "Used Dehumidifier 240L";
      imgContainer = (
        <div>
          <h1 className="prod-title">
            Used Dehumidifier 240L<br></br>
          </h1>
          <div>
            <p className="prod-desc"></p>
          </div>
          <br></br>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Dehumidifier 240L/dehumidifier dimension.jpg"}
                  alt="Dehumidifier dimension"
                  label="Dehumidifier dimension"
                ></img>
              </div>
              <div class="desc">Dehumidifier dimension</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Dehumidifier 240L/MS 9240B PHOTO 2.jpg"}
                  alt="MS 9240B Photo 2"
                  label="MS 9240B Photo 2"
                ></img>
              </div>
              <div class="desc">MS 9240B Photo 2</div>
            </div>
          </div>
          <div class="responsive">
            <div className="gallery-portrait">
              <div>
                <img
                  src={folderName + "/images/used/Used Dehumidifier 240L/MS 9240B PHOTO 3.jpg"}
                  alt="MS 9240B Photo 3"
                  label="MS 9240B Photo 3"
                ></img>
              </div>
              <div class="desc">MS 9240B Photo 3</div>
            </div>
          </div>
        </div>
      );
      validPage = true;
      break;
      // case "used-stainless-steel-sink-table":
      // title = "Used Stainless Steel Sink Table";
      // imgContainer = (
      //   <div>
      //     <h1 className="prod-title">
      //     Used Stainless Steel Sink Table<br></br>
      //     </h1>
      //     <div>
      //       <p className="prod-desc"></p>
      //     </div>
      //     <br></br>
      //     <div class="responsive">
      //       <div className="gallery-portrait">
      //         <div>
      //           <img
      //             src={folderName + "/images/used/Used Stainless Steel Sink Table/sus sink side view.jpg"}
      //             alt="SUS Sink Side view"
      //             label="SUS Sink Side view"
      //           ></img>
      //         </div>
      //         <div class="desc">SUS Sink Side view</div>
      //       </div>
      //     </div>
      //     <div class="responsive">
      //       <div className="gallery-portrait">
      //         <div>
      //           <img
      //             src={folderName + "/images/used/Used Stainless Steel Sink Table/sus sink with dimension.jpg"}
      //             alt="SUS Sink with dimension"
      //             label="SUS Sink with dimension"
      //           ></img>
      //         </div>
      //         <div class="desc">SUS Sink with dimension</div>
      //       </div>
      //     </div>
      //   </div>
      // );
      // validPage = true;
      // break;
  }

  var breadcrumbContainer = (
    <ul class="breadcrumb">
      <li>
        <a href="./../products">Products</a>
      </li>
      <li>{title}</li>
    </ul>
  );

  if (validPage === true) {
    return (
      <>
        <div className="img-container">
          {breadcrumbContainer}
          {imgContainer}
        </div>
      </>
    );
  } else {
    return <Redirect to="" />;
  }
}

export default Product;
