import React from "react";
import { Link } from "react-router-dom";

function Map() {
  const mapStyle = {
    border: "0",
    display: "grid",
  };
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.1540162828323!2d101.60514571399554!3d3.053414354587073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ca262bc2119%3A0xa939087bffa57575!2s3%2C%20Jalan%20Industri%20USJ%201%2F10%2C%20Taman%20Perindustrian%20Usj%201%2C%2047600%20Subang%20Jaya%2C%20Selangor!5e0!3m2!1sen!2smy!4v1623555698725!5m2!1sen!2smy"
      width="100%"
      height="350"
      style={mapStyle}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
  );
}

export default Map;
