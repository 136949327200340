import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

function Cards() {
  //env
  var folderName = "";

  return (
    <div className="cards">
      <h1>Our Products</h1>
      {/* <div className="violator-content">
        <span className="violator">Conveyor</span>
      </div> */}

      {/* <SimpleReactLightbox>
        <SRLWrapper> */}

      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/cards/conveyor/belt-main1.jpeg"
              text="Belt Conveyor"
              label="Belt Conveyor"
              path={folderName + "/product/belt-conveyor"}
              description="The belt conveyor is conveying various types of products &amp; can use in Injection Moulding,
              Electronic, Food, Airport Industry."
            />
            <CardItem
              src="images/cards/conveyor/roller-main.jpeg"
              text="Roller Conveyor"
              label="Roller Conveyor"
              path={folderName + "/product/roller-conveyor"}
              description="The roller conveyor is conveying the carton boxes, product in bags, pallets, etc. Mostly use in
              Logistic, Packaging, Airport &amp; etc."
            />
            <CardItem
              src="images/cards/conveyor/chain-main.jpeg"
              text="Chain Conveyor"
              label="Chain Conveyor"
              path={folderName + "/product/chain-conveyor"}
              description="Chain Conveyor is conveying the bottles, bags, wet product, boxes and other objects. With easy
              cleaning &amp; simple maintenance. Mostly use in Food &amp; Beverage Industry."
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/cards/conveyor/oven conveyor 3.jpg"
              text="Custom Made Conveyor"
              label="Custom Made Conveyor"
              path={folderName + "/product/custom-made-conveyor"}
              description=""
            />
            <CardItem
              src="images/cards/accessories/pvc_endcap.jpg"
              text="Conveyor Components"
              label="Conveyor Components"
              path={folderName + "/product/conveyor-components"}
              description=""
            />
            <CardItem
              src="images/cards/ac/ac_motor.jpg"
              text="AC Motor/Gearhead/Speed Controller"
              label="AC Motor/Gearhead/Speed Controller"
              path={folderName + "/product/ac-motor-gearhead-speed-controller"}
              description=""
            />
          </ul>
          <ul className="cards__items">
            {/* <CardItem
              src="images/cards/used/Office Racking (5 Level & 7 Level)/library book rack.jpg"
              text="Office Racking (5 Level & 7 Level)"
              label="Office Racking (5 Level & 7 Level)"
              path={folderName + "/product/office-racking"}
              description=""
            /> */}
            <CardItem
              src="images/cards/used/Used Band Sealing Machine (Horizontal) Model FR900/band sealer (front).jpg"
              text="Used Band Sealing Machine (Horizontal) Model FR900"
              label="Used Band Sealing Machine (Horizontal) Model FR900"
              path={folderName + "/product/used-band-sealing-machine"}
              description=""
            />
            <CardItem
              src="images/cards/used/Used Belt Conveyor/Used Belt Conveyor with SUS side table & Lighting Holder (size W500 x L8000mm x H760mm).jpg"
              text="Used Belt Conveyor"
              label="Used Belt Conveyor"
              path={folderName + "/product/used-belt-conveyor"}
              description=""
            />
             <CardItem
              src="images/cards/used/Used Dehumidifier 240L/dehumidifier dimension.jpg"
              text="Used Dehumidifier 240L"
              label="Used Dehumidifier 240L"
              path={folderName + "/product/used-dehumidifier-240l"}
              description=""
            />
          </ul>
          {/* <ul className="cards__items">
            <CardItem
              src="images/cards/used/Used Stainless Steel Sink Table/sus sink side view.jpg"
              text="Used Stainless Steel Sink Table"
              label="Used Stainless Steel Sink Table"
              path={folderName + "/product/used-stainless-steel-sink-table"}
              description=""
            />
          </ul> */}
        </div>
      </div>
      {/* </SRLWrapper>
      </SimpleReactLightbox> */}
      {/* <div className="violator-content">
        <span className="violator">Accessories</span>
      </div>

      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="cards__container">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <CardItem
                  src="images/cards/accessories/Profiles.jpg"
                  text="Profiles"
                  label="Profiles"
                  path="/services"
                />
                <CardItem
                  src="images/cards/accessories/pulleys.jpg"
                  text="Pulleys"
                  label="Pulleys"
                  path="/services"
                />
                <CardItem
                  src="images/cards/accessories/modular.jpg"
                  text="Modular Conveyor Accessories"
                  label="Modular Conveyor Accessories"
                  path="/services"
                />
                <CardItem
                  src="images/cards/accessories/gear_box.jpg"
                  text="Motor and Gearhead"
                  label="Motor and Gearhead"
                  path="/sign-up"
                />
              </ul>
              <ul className="cards__items">
                <CardItem
                  src="images/cards/accessories/pvc_endcap.jpg"
                  text="PVC Endcap"
                  label="PVC Endcap"
                  path="/sign-up"
                />
                <CardItem
                  src="images/cards/accessories/sprocket.jpg"
                  text="Sprocket"
                  label="Sprocket"
                  path="/sign-up"
                />
                <CardItem
                  src="images/cards/accessories/M16 Knuckle feet.jpg"
                  text="M16 Knuckle feet"
                  label="M16 Knuckle feet"
                  path="/sign-up"
                />
                <CardItem
                  src="images/cards/accessories/belting.jpg"
                  text="Belting (PVC, PU, Polyamide)"
                  label="Belting (PVC, PU, Polyamide)"
                  path="/sign-up"
                />
              </ul>
              <ul className="cards__items">
                <CardItem
                  src="images/cards/accessories/conveyor_metal_parts.jpg"
                  text="Conveyor Metal Parts"
                  label="Conveyor Metal Parts"
                  path="/sign-up"
                />
                <CardItem
                  src="images/cards/accessories/side_guide_holder.jpg"
                  text="Side Guide Holder"
                  label="Side Guide Holder"
                  path="/sign-up"
                />
                <CardItem
                  src="images/cards/accessories/plastic_chain.png"
                  text="Plastic Chain"
                  label="Plastic Chain"
                  path="/sign-up"
                />
              </ul>
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>

      <div className="violator-content">
        <span className="violator">Others</span>
      </div>

      <SimpleReactLightbox>
        <SRLWrapper>
          <div className="cards__container">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <CardItem
                  src="images/cards/others/pcb_loader_unloader_pusher.jpg"
                  text="PCB Loader-Unloader Pusher"
                  label="PCB Loader-Unloader Pusher"
                  path="/services"
                />
                <CardItem
                  src="images/cards/others/aluminimum _extrusion_profile.jpg"
                  text="Aluminimum Extrusion Profile Structure"
                  label="Aluminimum Extrusion Profile Structure"
                  path="/services"
                />
              </ul>
            </div>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox> */}
    </div>
  );
}

export default Cards;
