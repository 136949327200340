import React from "react";
import "../AboutUs/AboutUs.css";

function AboutUs(props) {
  var folderName = "";
  return (
    <>
      <div>
        <h1 className="prod-title">About Us</h1>

        <a
          className="cards__item__link-contact-us"
          href="/product/roller-conveyor"
        >
          <figure
            className="cards__item__pic-wrap-contact-us"
            data-category="Roller Conveyor"
          >
            <img
              className="cards__item__img"
              alt="Roller Conveyor"
              src={folderName +"/images/cards/conveyor/chain-main.jpeg"}
            />
          </figure>

          <div className="cards__items">
            <div className="cards__item">
              <div className="cards__item__info"></div>
              <p className="cards__item__text">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </p>
            </div>
          </div>
        </a>

        <a
          className="cards__item__link-contact-us"
          href="/product/roller-conveyor"
        >
          <div className="cards__items">
            <div className="cards__item">
              <div className="cards__item__info">
                <p className="cards__item__text">
                  {" "}
                  Our company founded in year 2010 &amp; located in Subang Jaya,
                  Selangor. We are specialized in design &amp; fabricate the
                  conveyor with our in-house facilities to meet our client’s
                  requirement.
                  <br></br> <br></br>
                  We are specialized in all kind of conveyor systems for variety
                  of industries, supplying related conveyor accessories &amp;
                  also maintenance/modification service.
                  <br></br> <br></br>
                  Our professional of team member is dedicated to designing,
                  fabricating, assemble, &amp; provide installation &amp;
                  commissioning for all conveyors. Our intention is to provide a
                  solution for client to enhance &amp; increase their work
                  efficiency with cost effective, time saving, user-friendly
                  use.
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}

export default AboutUs;
