import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./ContactForm.css";
const axios = require("axios");

// function onChange(value) {
//   console.log("Captcha value:", value);
// }

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      contact_no: "",
      message: "",
      isVerified: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(value) {
    console.log("Captcha value: ", value);
    this.setState({ isVerified: true });
  }

  mySubmitHandler = (event) => {
    //event.preventDefault();
    console.log(this.state);
    let contact_no = this.state.contact_no;

    var pass = true;
    if (!Number(contact_no)) {
      alert("Your contact no must be a number");
      pass = false;
    }

    if (this.state.message === "") {
      alert("Please type your message");
      pass = false;
    }

    var data = {
      name: this.state.name,
      email: this.state.email,
      contact_no: this.state.contact_no,
      message: this.state.message,
    };
    event.preventDefault();
    if (pass === true) {
      console.log(data);

      // endpoint
      //const postUrl = "http://localhost/primech-backend/public/api/contact";
      const postUrl =
        "https://primechfa.com/primech-backend/public/api/contact";

      axios
        .post(postUrl, this.state)
        .then((response) => {
          console.log(response);
          //this.setState({ message: "User created successfuly." });
          alert("Form submitted successfully.");
          this.setState({ name: "", email: "", contact_no: "", message: "" });
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };
  render() {
    return (
      <form id="contact-form" onSubmit={this.mySubmitHandler}>
        <div className="form-control">
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            onChange={this.myChangeHandler}
          />
        </div>
        <div className="form-control">
          <input
            type="email"
            name="email"
            onChange={this.myChangeHandler}
            placeholder="Email Address"
          />
        </div>
        <div className="form-control">
          <input
            type="text"
            name="contact_no"
            placeholder="Contact No"
            onChange={this.myChangeHandler}
          />
        </div>
        <div className="form-control">
          <textarea
            name="message"
            placeholder="Message here"
            onChange={this.myChangeHandler}
          ></textarea>
        </div>

        <ReCAPTCHA
          className="g-recaptcha"
          sitekey="6LdGQI0dAAAAAK3nz4PKlT1YwYAmSu5KNjn7FX-w"
          onChange={this.handleOnChange}
        />
        <br />

        <input type="submit" disabled={!this.state.isVerified} />
      </form>
    );

    //test key: 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
  }
}

//ReactDOM.render(<ContactForm />, document.getElementById("root"));

export default ContactForm;
