import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container">
      {/* <video src="/videos/video-1.mp4" autoPlay loop muted /> */}
      <h1>CONVEYOR MAKER</h1>
      <p>
        PRIMECH Industrial Supplies (M) Sdn Bhd, is a manufacturer of Conveyor
        System in Selangor, Malaysia.
        <br></br>
        <br></br>
        Belt Conveyor System, Roller Conveyor System, Chain Conveyor System
        &amp; any Custom Made Conveyor System.
      </p>

      <div className="hero-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          OUR PRODUCTS
        </Button>
        {/* <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={console.log("hey")}
        >
          WATCH TRAILER <i className="far fa-play-circle" />
        </Button> */}
      </div>
    </div>
  );
}

export default HeroSection;
