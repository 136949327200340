import React from "react";
import "../../App.css";
import Products from "./../Product/Product";
import Footer from "../Footer";
import Whatsapp from "../Whatsapp";

export default function Products_A(props) {
  const prodName = props.match.params.id;
  //return <h1 className="product-a">PRODUCTS A {prodName}</h1>;
  return (
    <>
      <Products product_name={prodName} />
      <Footer />
      <Whatsapp />
    </>
  );
}
