import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  //env
  var folderName = "";

  return (
    <div className="cards">
      <h1>Our Products</h1>

      <p className="prod-subheader">
        Our conveyor majority is use for Electronic, Food, Logistic, Packaging,
        Glove, Medicine, Injection moulding, Chemical Fertilizer & etc.
      </p>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/cards/conveyor/belt-main1.jpeg"
              text="Belt Conveyor"
              label="Belt Conveyor"
              path={folderName + "/product/belt-conveyor"}
              description="The belt conveyor is conveying various types of products &amp; can use in Injection Moulding,
              Electronic, Food, Airport Industry."
            />
            <CardItem
              src="images/cards/conveyor/roller-main.jpeg"
              text="Roller Conveyor"
              label="Roller Conveyor"
              path={folderName + "/product/roller-conveyor"}
              description="The roller conveyor is conveying the carton boxes, product in bags, pallets, etc. Mostly use in
              Logistic, Packaging, Airport &amp; etc."
            />
            <CardItem
              src="images/cards/conveyor/chain-main.jpeg"
              text="Chain Conveyor"
              label="Chain Conveyor"
              path={folderName + "/product/chain-conveyor"}
              description="Chain Conveyor is conveying the bottles, bags, wet product, boxes and other objects. With easy
              cleaning &amp; simple maintenance. Mostly use in Food &amp; Beverage Industry."
            />
          </ul>
          {/* <ul className="cards__items">
            <CardItem
              src="images/cards/conveyor/Flexible Gravity Wheel Conveyor.jpg"
              text="Custom Made Conveyor"
              label="Custom Made Conveyor"
              path="/product/custom-made-conveyor"
            />
            <CardItem
              src="images/cards/conveyor/Table Top Chain.jpg"
              text="Conveyor Components"
              label="Conveyor Components"
              path="/product/conveyor-components"
            />
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default Cards;
